import { Injectable } from '@angular/core';

import { createSelector } from 'reselect';

import { State } from '../reducers';
import * as fromPublicApi from '../reducers/public-api.reducer';

@Injectable()
export class PublicApiSelectors {
    /**
     * Get state.stats
     */
    getStatsState() {
        return ($state: State): any => $state.publicApi;
    }

    /**
     * Get stats from state.news
     */
    getData() {
        return createSelector(
            this.getStatsState(),
            fromPublicApi.getPublicApiData,
        );
    }
}
