import { prepareActions } from '@app/utils';
import { Action } from '@ngrx/store';

export const ActionTypes = prepareActions('[Public API]', [
    'PRODUCTS_DATA_REQUEST',
    'PRODUCTS_DATA_SUCCESS',
    'PRODUCTS_DATA_ERROR',
    'CLEAR_PRODUCTS_DATA',

    'PRODUCT_DATA_REQUEST',
    'PRODUCT_DATA_SUCCESS',
    'PRODUCT_DATA_ERROR',
    'CLEAR_PRODUCT_DATA',

    'PRODUCT_CATEGORIES_REQUEST',
    'PRODUCT_CATEGORIES_SUCCESS',
    'PRODUCT_CATEGORIES_ERROR',
    'CLEAR_PRODUCT_CATEGORIES',

    'PRODUCT_CATEGORY_REQUEST',
    'PRODUCT_CATEGORY_SUCCESS',
    'PRODUCT_CATEGORY_ERROR',
    'CLEAR_PRODUCT_CATEGORY',

    'PRODUCT_MEDIA_PROFILES_REQUEST',
    'PRODUCT_MEDIA_PROFILES_SUCCESS',
    'PRODUCT_MEDIA_PROFILES_ERROR',
    'CLEAR_PRODUCT_MEDIA_PROFILES',
]);

/**
 * Create 'Products Data Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class ProductsDataRequestAction implements Action {
    type = ActionTypes.PRODUCTS_DATA_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Data Success' Action
 * @param payload Contains object with products
 */
export class ProductsDataSuccessAction implements Action {
    type = ActionTypes.PRODUCTS_DATA_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Data Error' Action
 * @param payload Contains object with errors
 */
export class ProductsDataErrorAction implements Action {
    type = ActionTypes.PRODUCTS_DATA_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Products Data' Action
 * @param payload Contains object with default state
 */
export class ClearProductsDataAction implements Action {
    type = ActionTypes.CLEAR_PRODUCTS_DATA;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Data Request' Action
 * @param payload Contains object with list params (offset and limit)
 */
export class ProductDataRequestAction implements Action {
    type = ActionTypes.PRODUCT_DATA_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Data Succes' Action
 * @param payload Contains object with product
 */
export class ProductDataSuccessAction implements Action {
    type = ActionTypes.PRODUCT_DATA_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Data Error' Action
 * @param payload Contains object with errors
 */
export class ProductDataErrorAction implements Action {
    type = ActionTypes.PRODUCT_DATA_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Clear Product Data' Action
 * @param payload Contains object with default state
 */
export class ClearProductDataAction implements Action {
    type = ActionTypes.CLEAR_PRODUCT_DATA;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Categories Request' Action
 * @param payload Contains object with params
 */
export class ProductCategoriesRequestAction implements Action {
    type = ActionTypes.PRODUCT_CATEGORIES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Categories Succes' Action
 * @param payload Contains object with product categories
 */
export class ProductCategoriesSuccessAction implements Action {
    type = ActionTypes.PRODUCT_CATEGORIES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Categories Error' Action
 * @param payload Contains object with errors
 */
export class ProductCategoriesErrorAction implements Action {
    type = ActionTypes.PRODUCT_CATEGORIES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Claer Product Categories' Action
 * @param payload Contains object with default state
 */
export class ClearProductCategoriesAction implements Action {
    type = ActionTypes.CLEAR_PRODUCT_CATEGORIES;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Category Request' Action
 * @param payload Contains object with params
 */
export class ProductCategoryRequestAction implements Action {
    type = ActionTypes.PRODUCT_CATEGORY_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Category Succes' Action
 * @param payload Contains object with product category
 */
export class ProductCategorySuccessAction implements Action {
    type = ActionTypes.PRODUCT_CATEGORY_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Category Error' Action
 * @param payload Contains object with errors
 */
export class ProductCategoryErrorAction implements Action {
    type = ActionTypes.PRODUCT_CATEGORY_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Claer Product Category' Action
 * @param payload Contains object with default state
 */
export class ClearProductCategoryAction implements Action {
    type = ActionTypes.CLEAR_PRODUCT_CATEGORY;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Media Profiles Request' Action
 * @param payload Contains object with params
 */
export class ProductMediaProfilesRequestAction implements Action {
    type = ActionTypes.PRODUCT_MEDIA_PROFILES_REQUEST;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Media Profiles Succes' Action
 * @param payload Contains object with media profiles
 */
export class ProductMediaProfilesSuccessAction implements Action {
    type = ActionTypes.PRODUCT_MEDIA_PROFILES_SUCCESS;

    constructor(public payload?: any) {}
}

/**
 * Create 'Product Media Profiles Error' Action
 * @param payload Contains object with errors
 */
export class ProductMediaProfilesErrorAction implements Action {
    type = ActionTypes.PRODUCT_MEDIA_PROFILES_ERROR;

    constructor(public payload?: any) {}
}

/**
 * Create 'Claer Product Media Profiles' Action
 * @param payload Contains object with default state
 */
export class ClearProductMediaProfilesAction implements Action {
    type = ActionTypes.CLEAR_PRODUCT_MEDIA_PROFILES;

    constructor(public payload?: any) {}
}

export type Actions =
    | ProductsDataRequestAction
    | ProductsDataSuccessAction
    | ProductsDataErrorAction
    | ClearProductsDataAction
    | ProductDataRequestAction
    | ProductDataSuccessAction
    | ProductDataErrorAction
    | ClearProductDataAction
    | ProductCategoriesRequestAction
    | ProductCategoriesSuccessAction
    | ProductCategoriesErrorAction
    | ClearProductCategoriesAction
    | ProductCategoryRequestAction
    | ProductCategorySuccessAction
    | ProductCategoryErrorAction
    | ClearProductCategoryAction
    | ProductMediaProfilesRequestAction
    | ProductMediaProfilesSuccessAction
    | ProductMediaProfilesErrorAction
    | ClearProductMediaProfilesAction;
